var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("div", { staticClass: "align-self-start text-left" }, [
            _c("img", {
              staticClass: "warning-img",
              attrs: {
                src: "/assets/img/svg/controls/coach_outline.svg",
                alt: "",
              },
            }),
            _vm._v(" "),
            _vm.displayName
              ? _c("p", { staticClass: "mt-1 mb-0" }, [
                  _vm._v(_vm._s(_vm.botName)),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "bounce", appear: "" } }, [
            _c(
              "div",
              { staticClass: "box2 sb11 align-self-start ml-3" },
              [_vm._t("content")],
              2
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }