var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex" }, [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "b-badge",
              {
                staticClass: "align-self-center mr-3 team btn-shadow",
                attrs: { pill: "", variant: "light" },
              },
              [
                _c("b-avatar", {
                  staticClass: "align-self-center",
                  attrs: { size: "2.5em" },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "ml-2 align-self-center" }, [
                  _vm._v("Loading teams"),
                ]),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "d-flex" },
          _vm._l(_vm.teams, function (team, index) {
            return _c(
              "b-badge",
              {
                key: `team-${index}`,
                staticClass:
                  "d-flex align-self-center mr-3 team btn-shadow animate__animated animate__fadeIn",
                class: { "active-team": _vm.selectedTeam.id === team.id },
                attrs: {
                  variant:
                    _vm.selectedTeam.id === team.id ? "warning" : "light",
                  pill: "",
                },
                on: {
                  click: function ($event) {
                    return _vm.onTeamSelect(team)
                  },
                },
              },
              [
                _c("b-avatar", {
                  staticClass: "align-self-center",
                  attrs: {
                    src: team.avatar,
                    size: "2.5em",
                    text: _vm.getNameInitials(team.name),
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "ml-2 align-self-center" }, [
                  _vm._v(_vm._s(team.name)),
                ]),
              ],
              1
            )
          }),
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }